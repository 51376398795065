import {ExtraOptions, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MatchPublicPageGuard} from "./_guards/public-page.guard";
import {MatchSirensCategoryGuard} from "./_guards/sirens-category.guard";
import {MatchRegionChildGuard} from "./_guards/region-child.guard";
import {MatchRegionSignalsGuard} from "./_guards/region-signals.guard";
import {IframeMatchGuard} from "./_guards/iframe-page.guard";
import {MatchRegionGuard} from "./_guards/region.guard";
import {MatchWidgetGuard} from "./_guards/widget.guard";


const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  enableTracing: false,
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 30]
};

const routWithComingSoon = [
  {
    path: '',
    children: [
      {
        path: "district/0",
        redirectTo: "/region/avtonomna-respublika-krym/air/"
      },
      {
        path: "district/1",
        redirectTo: "/region/vinnytska-oblast/air/"
      },
      {
        path: "district/2",
        redirectTo: "/region/volynska-oblast/air/"
      },
      {
        path: "district/3",
        redirectTo: "/region/dnipropetrovska-oblast/air/"
      },
      {
        path: "district/4",
        redirectTo: "/region/donetska-oblast/air/"
      },
      {
        path: "district/5",
        redirectTo: "/region/zhytomyrska-oblast/air/"
      },
      {
        path: "district/6",
        redirectTo: "/region/zakarpatska-oblast/air/"
      },
      {
        path: "district/7",
        redirectTo: "/region/zaporizka-oblast/air/"
      },
      {
        path: "district/8",
        redirectTo: "/region/ivano-frankivska-oblast/air/"
      },
      {
        path: "district/9",
        redirectTo: "/region/kyyivska-oblast/air/"
      },
      {
        path: "district/10",
        redirectTo: "/region/kirovohradska-oblast/air/"
      },
      {
        path: "district/11",
        redirectTo: "/region/luhanska-oblast/air/"
      },
      {
        path: "district/12",
        redirectTo: "/region/lvivska-oblast/air/"
      },
      {
        path: "district/13",
        redirectTo: "/region/mykolayivska-oblast/air/"
      },
      {
        path: "district/14",
        redirectTo: "/region/odeska-oblast/air/"
      },
      {
        path: "district/15",
        redirectTo: "/region/poltavska-oblast/air/"
      },
      {
        path: "district/16",
        redirectTo: "/region/rivnenska-oblast/air/"
      },
      {
        path: "district/17",
        redirectTo: "/region/sumska-oblast/air/"
      },
      {
        path: "district/18",
        redirectTo: "/region/ternopilska-oblast/air/"
      },
      {
        path: "district/19",
        redirectTo: "/region/kharkivska-oblast/air/"
      },
      {
        path: "district/20",
        redirectTo: "/region/khersonska-oblast/air/"
      },
      {
        path: "district/21",
        redirectTo: "/region/khmelnytska-oblast/air/"
      },
      {
        path: "district/22",
        redirectTo: "/region/cherkaska-oblast/air/"
      },
      {
        path: "district/23",
        redirectTo: "/region/chernivetska-oblast/air/"
      },
      {
        path: "district/24",
        redirectTo: "/region/chernihivska-oblast/air/"
      },
      {
        path: "district/25",
        redirectTo: "/region/kyyiv/air/"
      },
      {
        path: "district/26",
        redirectTo: "/region/sevastopol/air/"
      },
      {
        path: 'district',
        redirectTo: '/region'
      },

      {
        path: 'contacts',
        loadChildren: () => import('./_ui/pages/public/bh-page-contacts/bh-page-contacts.module').then((mod) => mod.BhPageContactsModule),
      },
      {
        path: 'terms',
        loadChildren: () => import('./_ui/pages/public/bh-page-terms/bh-page-terms.module').then((mod) => mod.BhPageTermsModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./_ui/pages/public/bh-page-privacy-policy/bh-page-privacy-policy.module').then((mod) => mod.BhPagePrivacyPolicyModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./_ui/pages/public/bh-page-search/bh-page-search.module').then((mod) => mod.BhPageSearchModule),
      },
      {
        path: 'region',
        children: [
          {
            path: ':region_slug/:rayon_slug/:hromad_slug/:signal_type',
            canMatch: [MatchRegionSignalsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-region-info/bh-page-region-info.module').then((mod) => mod.BhPageRegionInfoModule),
          },
          {
            path: ':region_slug/:rayon_slug/:hromad_slug',
            canMatch: [MatchRegionChildGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-region-info/bh-page-region-info.module').then((mod) => mod.BhPageRegionInfoModule),
          },
          {
            path: ':region_slug/:rayon_slug/:signal_type',
            canMatch: [MatchRegionSignalsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-region-info/bh-page-region-info.module').then((mod) => mod.BhPageRegionInfoModule),
          },


          {
            path: ':region_slug/:rayon_slug',
            canMatch: [MatchRegionChildGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-region-info/bh-page-region-info.module').then((mod) => mod.BhPageRegionInfoModule),
          },
          {
            path: ':region_slug/:signal_type',
            canMatch: [MatchRegionSignalsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-region-info/bh-page-region-info.module').then((mod) => mod.BhPageRegionInfoModule),
          },
          {
            path: ':region_slug',
            canMatch: [MatchRegionGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-region-info/bh-page-region-info.module').then((mod) => mod.BhPageRegionInfoModule),
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-region/bh-page-region.module').then((mod) => mod.BhPageRegionModule),
          },
        ]
      },
      {
        path: 'rules',
        children: [
          {
            path: ':signal_type',
            canMatch: [MatchRegionSignalsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-rules/bh-page-rules.module').then((mod) => mod.BhPageRulesModule),
          },

          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-rules/bh-page-rules.module').then((mod) => mod.BhPageRulesModule),
          },
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./_ui/pages/public/bh-page-documentation/bh-page-documentation.module').then((mod) => mod.BhPageDocumentationModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./_ui/pages/public/bh-page-settings/bh-page-settings.module').then((mod) => mod.BhPageSettingsModule),
        data: {
          noindex: true
        }
      },

      {
        path: 'air',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'air',
        }
      },
      {
        path: 'chemical',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'chemical',
        }
      },
      {
        path: 'radiation',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'radiation',
        }
      },
      {
        path: 'artillery',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'artillery',
        }
      },
      {
        path: 'fight',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'fight',
        }
      },
      {
        path: 'fire',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'fire',
        }
      },
      {
        path: 'water',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'water',
        }
      },
      {
        path: 'weather',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'weather',
        }
      },
      {
        path: 'wind',
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
        canMatch: [MatchSirensCategoryGuard],
        data: {
          signal: 'wind',
        }
      },

      {
        path: 'widget',
        canMatch: [MatchPublicPageGuard, MatchWidgetGuard],
        loadChildren: () => import('./_ui/pages/public/bh-page-widget/bh-page-widget.module').then((mod) => mod.BhPageWidgetModule),
      },

      {
        path: 'ru',
        canMatch: [MatchPublicPageGuard],
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
      },
      {
        path: 'en',
        canMatch: [MatchPublicPageGuard],
        loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
      },
      {
        path: '',
        children: [
          {
            path: '',
            canMatch: [MatchPublicPageGuard, IframeMatchGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-iframe/bh-page-iframe.module').then((mod) => mod.BhPageIframeModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          }
        ]
      },
      {
        path: '**',
        redirectTo: '/'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([], routerOptions),
    RouterModule.forChild(routWithComingSoon),
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
