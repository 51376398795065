import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext, Store} from "@ngxs/store";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {SettingsModel} from "../_models/settings.model";
import {
  ChangeKioskModeAction,
  ChangeLanguageAction,
  ChangeMapStyleAction,
  ChangeSoundAction,
  ChangeStyleAction,
  ClearAppAction,
  SetSoundAction
} from "../_actions/settings.actions";
import {TranslocoService} from "@ngneat/transloco";

import {LocaleService} from "../../../_services/locale.service";
import {isPlatformBrowser} from "@angular/common";
import {RouterNavigation} from "@ngxs/router-plugin";
import {CrumbsModel} from "../../crumbs/_models/crumbs.model";
import {Router} from "@angular/router";


export const _SettingsDefault: SettingsModel = {
  version: 7,
  style: 'light',
  map_style: 'brand',
  languages: 'ua',
  sound: true,
  kiosk: false
};

@State<SettingsModel>({
  name: 'BH_APP_SETTINGS',
  defaults: _SettingsDefault,
})
@Injectable()
export class SettingsState implements NgxsOnInit, NgxsOnChanges {
  isBrowser: boolean;

  constructor(private store: Store, private router: Router, private transloco: TranslocoService, private localeService: LocaleService,  @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    const state = ctx.getState();
    if(!state.version || state.version && state.version != _SettingsDefault.version) {
      ctx.setState(_SettingsDefault);
      ctx.dispatch(new ClearAppAction());
    }
    if(state.languages != _SettingsDefault.languages) {
      this.transloco.setActiveLang(state.languages);
    }
    this.localeService.initLocale(state.languages);
    if (this.isBrowser) {
      this._detectUserStyleSchema(ctx);
    }
  }
  ngxsOnChanges(change: NgxsSimpleChange<SettingsModel>): void {}


  @Selector()
  static selectSettings( state: SettingsModel ) {
    return state;
  }

  @Action(RouterNavigation)
  routerNavigation(ctx: StateContext<CrumbsModel>, _router) {
    if(this.isBrowser && _router.routerState.queryParams.utm_medium) {
      this.router.navigate(['/']).then();
    }
    if(this.isBrowser && _router.routerState.queryParams.utm_source) {
      this.router.navigate(['/']).then();
    }
    if(this.isBrowser && _router.routerState.queryParams.PageSpeed) {
      this.router.navigate(['/']).then();
    }

  }

  @Action(ClearAppAction)
  public async clearAppAction(ctx: StateContext<SettingsModel>) {
    ctx.setState(_SettingsDefault);
    if (this.isBrowser) {
      // Clear cookies
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const cookieName = cookie.split('=')[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }

      // Clear cache storage
      const cacheNames = await window.caches.keys();
      for (let name of cacheNames) {
        await window.caches.delete(name);
      }

      // Delete IndexedDB databases
      const dbs = await window.indexedDB.databases();
      dbs.forEach(db => window.indexedDB.deleteDatabase(db.name));

      // Clear localStorage and sessionStorage
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
  }


  @Action(ChangeLanguageAction)
  public changeLanguageAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      languages:  payload.language,
    });
    this.localeService.setLocale(payload.language);
  }
  @Action(ChangeSoundAction)
  public changeSoundAction(ctx: StateContext<SettingsModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      sound: !state.sound
    });
  }
  @Action(ChangeKioskModeAction)
  public changeKioskModeAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    let _mode = !state.kiosk;
    if(typeof payload.mode != 'undefined') {
      _mode = payload.mode;
    }
    ctx.patchState({
      ...state,
      kiosk: _mode
    });
  }
  @Action(ChangeStyleAction)
  public changeStyleAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      style:  payload.style,
    });
  }
  @Action(ChangeMapStyleAction)
  public changeMapStyleAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      map_style:  payload.map_style,
    });
  }
  @Action(SetSoundAction)
  public setSoundAction(ctx: StateContext<SettingsModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      sound:  payload.sound,
    });
  }


  _detectUserStyleSchema(ctx?: StateContext<SettingsModel>): void {
    const state = ctx.getState();
    if(!window.matchMedia) {
      return;
    }
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.store.dispatch(new ChangeStyleAction('dark'));
      if (state.style == 'light') {
        ctx.dispatch(new ChangeStyleAction('light'));
      }
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      const _colorScheme = event.matches ? "dark" : "light";
      ctx.dispatch(new ChangeStyleAction(_colorScheme));
    });

  }

}
