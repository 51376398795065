import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngxs/store";
import {SignalsState} from "../_stores/signals/_state/signals.state";

@Injectable({
  providedIn: 'root',
})
export class MatchRegionSignalsGuard implements CanMatch {

  constructor(private store: Store) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const _last = segments[segments.length - 1].path;
    const _pre_last = segments[segments.length - 2]? segments[segments.length - 2].path: 'none';
    const _pre_last_is_signal = this.store.selectSnapshot(SignalsState.selectSignalsTypes).find((_type) => _type.slug == _pre_last);
    if(_pre_last_is_signal) {
      return false;
    } else {
      return !!this.store.selectSnapshot(SignalsState.selectSignalsTypes).find((_type) => _type.slug == segments[segments.length - 1].path);
    }


  }

}
