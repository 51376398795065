import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngxs/store";

@Injectable({
  providedIn: 'root',
})
export class MatchRegionGuard implements CanMatch {

  constructor(private store: Store) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const conditions = ['-', '_', 'sevastopol', 'kyyiv'];
    return conditions.some(condition => segments[segments.length - 1].path.includes(condition));
  }

}
