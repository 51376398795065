import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {RouterNavigated, RouterNavigation} from '@ngxs/router-plugin';

import {TranslocoService} from "@ngneat/transloco";
import {SeoService} from "../../../_services/seo.service";
import {CrumbsModel} from "../_models/crumbs.model";
import {ModalCloseAction} from "../../modal/_actions/modal.actions";
import {take} from "rxjs";
import {ChangeKioskModeAction, ChangeLanguageAction} from "../../settings/_actions/settings.actions";


export const _CrumbsDefault: CrumbsModel = {
  title: ' ',
  subtitle: ' ',
  description: ' ',
  breadcrumbs: []
};

@State<CrumbsModel>({
  name: 'BH_APP_CRUMBS',
  defaults: _CrumbsDefault,
})

@Injectable()
export class CrumbsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {
  constructor(private store: Store, private transloco: TranslocoService, private seoService: SeoService) {}

  ngxsAfterBootstrap(ctx?: StateContext<CrumbsModel>): void {}

  ngxsOnChanges(change: NgxsSimpleChange): void {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  @Selector()
  static selectCrumbs(state: CrumbsModel) {
    return state;
  }

  @Action(RouterNavigated)
  routerRequest(ctx: StateContext<CrumbsModel>, payload) {
    ctx.dispatch(new ModalCloseAction());
  }


  @Action(RouterNavigation)
  async routerNavigation(ctx: StateContext<CrumbsModel>, payload) {
    const _crumb_url = payload.routerState.url.split('?')[0].split('#')[0];
    let   _crumb_link = '/';
    let   _crumbs_translated  = {..._CrumbsDefault};
    const _global_translate = await this.transloco.selectTranslation().pipe(take(1)).toPromise();
    let _translate = {..._global_translate};
    const _window_locate = 'https://alarmmap.online';

    _crumbs_translated.og = {
      "update": false,
      "title": _translate['nav.home_alt'] + " - " +_translate['global.app_name'],
      "description": _translate['global.app_description'],
      "site_name": _translate['global.app_name'],
      "image": _window_locate+"/assets/images/posters/",
      "image:secure_url": _window_locate+"/assets/images/posters/amo_default.png"
    };

    if (_crumb_url === '/') {
      _crumbs_translated.title = '';
      _crumbs_translated.description = _translate['seo.nav.main'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['global.app_name'],
        description:_translate['seo.nav.main'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url === '/documentation/') {
      _crumbs_translated.title = _translate['nav.documentation'];
      _crumbs_translated.title_alt = 'nav.documentation_alt';
      _crumbs_translated.description = _translate['seo.nav.documentation'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.documentation'] + " - " +_translate['global.app_name'],
        description:_translate['seo.nav.documentation'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url === '/settings/') {
      _crumbs_translated.title = _translate['nav.settings'];
      _crumbs_translated.title_alt = 'nav.settings_alt';
      _crumbs_translated.description = _translate['seo.nav.settings'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.settings'] + " - " +_translate['global.app_name'],
        description:_translate['seo.nav.settings'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url === '/contacts/') {
      _crumbs_translated.title = _translate['nav.contacts'];
      _crumbs_translated.title_alt = 'nav.contacts';
      _crumbs_translated.description = _translate['seo.nav.contacts'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.contacts'] + " - " +_translate['global.app_name'],
        description:_translate['seo.nav.contacts'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url === '/terms/') {
      _crumbs_translated.title = _translate['nav.terms'];
      _crumbs_translated.title_alt = 'nav.terms';
      _crumbs_translated.description = _translate['seo.nav.terms'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.terms'] + " - " +_translate['global.app_name'],
        description:_translate['seo.nav.terms'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url === '/privacy-policy/') {
      _crumbs_translated.title = _translate['nav.privacy-policy'];
      _crumbs_translated.title_alt = 'nav.privacy-policy';
      _crumbs_translated.description = _translate['seo.nav.privacy-policy'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.privacy-policy'] + " - " +_translate['global.app_name'],
        description:_translate['seo.nav.privacy-policy'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url.includes('/region/')) {
      const _route_params = payload.routerState.params;
      if(_route_params.region_slug) {
        if(_route_params.rayon_slug) {
          if(_route_params.hromad_slug) {
            if(_route_params.signal_type) {
              _crumbs_translated.title = _translate['nav.'+_route_params.signal_type]+' '+_translate['global.in']+' '+_translate['nav.'+_route_params.hromad_slug];
              _crumbs_translated.title_alt = 'nav.'+_route_params.hromad_slug;
              _crumbs_translated.description = _translate['seo.nav.region_signal'].replaceAll('{{signal_region}}', _translate['nav.'+_route_params.hromad_slug]).replaceAll('{{signal_type}}', _translate['seo.signals.'+_route_params.signal_type]);
              _crumbs_translated.og = {
                ..._crumbs_translated.og,
                update: true,
                title: _translate['nav.'+_route_params.hromad_slug] + " - " + _translate['global.app_name'],
                description:_translate['seo.nav.region_signal'].replaceAll('{{signal_region}}', _translate['nav.'+_route_params.hromad_slug]).replaceAll('{{signal_type}}', _translate['seo.signals.'+_route_params.signal_type]),
                image: _crumbs_translated.og.image + 'amo_'+_route_params.signal_type+'.png'
              }
            }
            else {
              _crumbs_translated.title = _translate['nav.'+_route_params.hromad_slug];
              _crumbs_translated.title_alt = 'nav.'+_route_params.hromad_slug;
              _crumbs_translated.description = _translate['seo.nav.region_slug'].replaceAll('{{slug}}', _translate['nav.'+_route_params.hromad_slug]+', '+_translate['nav.'+_route_params.rayon_slug]+', '+_translate['nav.'+_route_params.region_slug] )
              _crumbs_translated.og = {
                ..._crumbs_translated.og,
                update: true,
                title: _translate['nav.'+_route_params.hromad_slug] + " - " + _translate['global.app_name'],
                description:_translate['seo.nav.region_slug'].replaceAll('{{slug}}', _translate['nav.'+_route_params.hromad_slug]+', '+_translate['nav.'+_route_params.rayon_slug]+', '+_translate['nav.'+_route_params.region_slug]),
                image: _crumbs_translated.og.image + 'amo_default.png'
              }
            }
          }
          else if(_route_params.signal_type) {
            _crumbs_translated.title = _translate['nav.'+_route_params.signal_type]+' '+_translate['global.in']+' '+_translate['nav.'+_route_params.rayon_slug];
            _crumbs_translated.title_alt = 'nav.'+_route_params.rayon_slug;
            _crumbs_translated.description = _translate['seo.nav.region_signal'].replaceAll('{{signal_region}}', _translate['nav.'+_route_params.rayon_slug]).replaceAll('{{signal_type}}', _translate['seo.signals.'+_route_params.signal_type]);
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _translate['nav.'+_route_params.rayon_slug] + " - " + _translate['global.app_name'],
              description:_translate['seo.nav.region_signal'].replaceAll('{{signal_region}}', _translate['nav.'+_route_params.rayon_slug]).replaceAll('{{signal_type}}', _translate['seo.signals.'+_route_params.signal_type]),
              image: _crumbs_translated.og.image + 'amo_'+_route_params.signal_type+'.png'
            }
          }
          else {
            _crumbs_translated.title = _translate['nav.'+_route_params.rayon_slug];
            _crumbs_translated.title_alt = 'nav.'+_route_params.rayon_slug;
            _crumbs_translated.description = _translate['seo.nav.region_slug'].replaceAll('{{slug}}', _translate['nav.'+_route_params.rayon_slug]+', '+_translate['nav.'+_route_params.region_slug] )
            _crumbs_translated.og = {
              ..._crumbs_translated.og,
              update: true,
              title: _translate['nav.'+_route_params.rayon_slug] + " - " + _translate['global.app_name'],
              description:_translate['seo.nav.region_slug'].replaceAll('{{slug}}', _translate['nav.'+_route_params.rayon_slug]+', '+_translate['nav.'+_route_params.region_slug]),
              image: _crumbs_translated.og.image + 'amo_default.png'
            }
          }
        }
        else if(_route_params.signal_type) {
          _crumbs_translated.title = _translate['nav.'+_route_params.signal_type]+' '+_translate['global.in']+' '+_translate['nav.'+_route_params.region_slug];
          _crumbs_translated.title_alt = 'nav.'+_route_params.region_slug;
          _crumbs_translated.description = _translate['seo.nav.region_signal'].replaceAll('{{signal_region}}', _translate['nav.'+_route_params.region_slug]).replaceAll('{{signal_type}}', _translate['seo.signals.'+_route_params.signal_type]);
          _crumbs_translated.og = {
            ..._crumbs_translated.og,
            update: true,
            title: _translate['nav.'+_route_params.region_slug] + " - " + _translate['global.app_name'],
            description:_translate['seo.nav.region_signal'].replaceAll('{{signal_region}}', _translate['nav.'+_route_params.region_slug]).replaceAll('{{signal_type}}', _translate['seo.signals.'+_route_params.signal_type]),
            image: _crumbs_translated.og.image + 'amo_'+_route_params.signal_type+'.png'
          }
        }
        else {
          _crumbs_translated.title = _translate['nav.'+_route_params.region_slug];
          _crumbs_translated.title_alt = 'nav.'+_route_params.region_slug;
          _crumbs_translated.description = _translate['seo.nav.region_slug'].replaceAll('{{slug}}', _translate['nav.'+_route_params.region_slug]);
          _crumbs_translated.og = {
            ..._crumbs_translated.og,
            update: true,
            title: _translate['nav.'+_route_params.region_slug] + " - " + _translate['global.app_name'],
            description: _translate['seo.nav.region_slug'].replaceAll('{{slug}}', _translate['nav.'+_route_params.region_slug]),
            image: _crumbs_translated.og.image + 'amo_default.png'
          }
        }
      }
      else {
        _crumbs_translated.title = _translate['nav.region'];
        _crumbs_translated.title_alt = 'nav.region';
        _crumbs_translated.description = _translate['seo.nav.region'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['nav.region'] + " - " + _translate['global.app_name'],
          description: _translate['seo.nav.region'],
          image: _crumbs_translated.og.image + 'amo_default.png'
        }
      }
    }
    if (_crumb_url.includes('/rules/')) {
      const _route_params = payload.routerState.params;
      const _rules_translate = await this.transloco.selectTranslation('pages/rules/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
      _translate = {..._translate, ..._rules_translate};

      if(_route_params.signal_type) {
        _crumbs_translated.title = _translate['signal_rules.'+_route_params.signal_type+'.title'];
        _crumbs_translated.description = _translate['signal_rules.'+_route_params.signal_type+'.desk'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['signal_rules.'+_route_params.signal_type+'.title'] + " - " + _translate['global.app_name'],
          title_alt: null,
          description: _translate['signal_rules.'+_route_params.signal_type+'.desk'],
          image: _crumbs_translated.og.image + 'amo_'+_route_params.signal_type+'.png'
        }
      }
      else {
        _crumbs_translated.title = _translate['signal_rules.all.title'];
        _crumbs_translated.title_alt = null;
        _crumbs_translated.description = _translate['signal_rules.all.desk'];
        _crumbs_translated.og = {
          ..._crumbs_translated.og,
          update: true,
          title: _translate['signal_rules.all.title'] + " - " + _translate['global.app_name'],
          description: _translate['signal_rules.all.desk'],
          image: _crumbs_translated.og.image + 'amo_default.png'
        }
      }
    }
    if (_crumb_url.includes('/search/')) {
      _crumbs_translated.title = _translate['nav.search'];
      _crumbs_translated.title_alt = 'nav.search';
      _crumbs_translated.description = _translate['seo.nav.search'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['nav.search'] + " - " +_translate['global.app_name'],
        description:_translate['seo.nav.search'],
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (['/air/', '/chemical/','/radiation/','/artillery/','/fight/','/fire/','/water/','/weather/','/wind/'].includes(_crumb_url)) {
      const _signal = _crumb_url.replaceAll('/', '');
      const _product_translate = await this.transloco.selectTranslation('components/signals/'+this.transloco.getActiveLang()).pipe(take(1)).toPromise();
      _translate = {..._translate, ..._product_translate};
      _crumbs_translated.title = _translate['types.'+_signal+'.seo.title'];
      _crumbs_translated.title_alt = 'types.'+_signal+'.seo.title';
      _crumbs_translated.description = _translate['types.'+_signal+'.seo.desc'];
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: _translate['types.'+_signal+'.seo.title'] + " - " +_translate['global.app_name'],
        description: _translate['types.'+_signal+'.seo.desc'],
        image: _crumbs_translated.og.image+'amo_'+_signal+'.png'
      }
    }

    if (_crumb_url === '/ru/') {
      this.transloco.setActiveLang('ru');
      this.store.dispatch(new ChangeLanguageAction('ru'));
      _crumbs_translated.title = '';
      _crumbs_translated.description = "Карта Чрезвычайных Ситуаций: мгновенный доступ к актуальной информации о чрезвычайных событиях и тревогах в Украине. Следите за безопасностью в реальном времени с помощью нашего интерактивного инструмента мониторинга. От природных катастроф до техногенных аварий - будьте в курсе и защищены.";
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: "Карта Чрезвычайных Ситуаций",
        description:"Карта Чрезвычайных Ситуаций: мгновенный доступ к актуальной информации о чрезвычайных событиях и тревогах в Украине. Следите за безопасностью в реальном времени с помощью нашего интерактивного инструмента мониторинга. От природных катастроф до техногенных аварий - будьте в курсе и защищены.",
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }
    if (_crumb_url === '/en/') {
      this.transloco.setActiveLang('en');
      this.store.dispatch(new ChangeLanguageAction('en'));
      _crumbs_translated.title = '';
      _crumbs_translated.description = "Emergency Situations Map: instant access to up-to-date information on emergency events and alerts in Ukraine. Follow safety in real-time with our interactive monitoring tool. From natural disasters to man-made accidents - be informed and protected.";
      _crumbs_translated.og = {
        ..._crumbs_translated.og,
        update: true,
        title: "Emergency Situations Map",
        description:"Emergency Situations Map: instant access to up-to-date information on emergency events and alerts in Ukraine. Follow safety in real-time with our interactive monitoring tool. From natural disasters to man-made accidents - be informed and protected.",
        image: _crumbs_translated.og.image+'amo_default.png'
      }
    }

    if(['/air/', '/chemical/','/radiation/','/artillery/','/fight/','/fire/','/water/','/weather/','/wind/'].includes(_crumb_url) || _crumb_url === '/') {

    } else {
      this.store.dispatch(new ChangeKioskModeAction(false));
    }

    _crumbs_translated.breadcrumbs = _crumb_url.split('/').filter(crumb => crumb.length > 1 && crumb != payload.routerState.params.token).reduce((crumbs_array, item) => {
      _crumb_link += item +'/';
      let _item_crumb = {
        name: item,
        translate: _translate['nav.'+item],
        link: _crumb_link.slice(0, -1)
      }
      return [...crumbs_array, _item_crumb];
    }, []);

    this.seoService.updateTitle(_crumbs_translated.title);
    this.seoService.updateDescription(_crumbs_translated.description);
    this.seoService.updateOgMetaTags(_crumbs_translated.og);
    this.seoService.updateCanonicalURL(_crumb_url, payload.routerState.data);
    this.seoService.updateNoIndexUrl(payload.routerState.data);


    if (_crumb_url === '/') {
      this.seoService.initSchema('bh-org-schema');
      this.seoService.updateSchemaOrganization();
      this.seoService.initSchema('bh-website-schema');
      this.seoService.updateSchemaWebsite();
      this.seoService.clearSchemaBreadcrumb();
    }
    else if(_crumb_url === '/ru/' || _crumb_url === '/en/') {
      this.seoService.clearSchemaOrganization();
      this.seoService.clearSchemaWebsite();
      this.seoService.clearSchemaBreadcrumb();
    }
    else {
      if(_crumb_url != '/') {
        this.seoService.clearSchemaOrganization();
        this.seoService.clearSchemaWebsite();
      }
      this.seoService.initSchema('bh-breadcrumb-schema');
    }

    if (_crumb_url !== '/') {
      this.seoService.updateSchemaBreadcrumbs(_crumbs_translated);
    }
    ctx.setState(_crumbs_translated);
  }
}
