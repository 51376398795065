import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnderscorePipe} from "./_pipes/underscore.pipe";
import {StripHtmlPipe} from "./_pipes/striphtml.pipe";
import {TimeFullPipe} from "./_pipes/time-full.pipe";
import {ResultViewerPipe} from "./_pipes/result-viewer.pipe";
import {RandomPipe} from "./_pipes/random.pipe";
import {SafePipe} from "./_pipes/safe.pipe";
import {SortPipe} from "./_pipes/sort.pipe";
import {DiffPipe} from "./_pipes/diff.pipe";
import {TimerPipe} from "./_pipes/time.pipe";
import {RandomOnePipe} from "./_pipes/random_one.pipe";
import {RandomNumberPipe} from "./_pipes/random_number.pipe";
import {FilterLengthPipe} from "./_pipes/filter_type_length.pipe";
import {TimeAgoPipe} from "./_pipes/time-ago.pipe";
import {CustomDateRangePipe} from "./_pipes/customDateRange.pipe";
import {DomainHrefDirective} from "./_directives/href.directive";


@NgModule({
    declarations: [
        UnderscorePipe,
        DiffPipe,
        SafePipe,
        SortPipe,
        StripHtmlPipe,
        TimerPipe,
        RandomPipe,
        ResultViewerPipe,
        TimeFullPipe,
        RandomOnePipe,
        RandomNumberPipe,
        FilterLengthPipe,
        TimeAgoPipe,
        CustomDateRangePipe,
        DomainHrefDirective
    ],
    exports: [
        UnderscorePipe,
        DiffPipe,
        SafePipe,
        SortPipe,
        StripHtmlPipe,
        TimerPipe,
        RandomPipe,
        ResultViewerPipe,
        TimeFullPipe,
        RandomOnePipe,
        RandomNumberPipe,
        FilterLengthPipe,
        TimeAgoPipe,
        CustomDateRangePipe,
        DomainHrefDirective
    ],
  imports: [CommonModule],
})
export class AppPipesModule {}
