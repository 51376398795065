import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {AtuGeoItemModel, AtuItemModel, AtuModel} from "../_models/atu.model";
import {AtuService} from "../_services/atu.service";
import {Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {LoadAtuActualGeo, LoadAtuFromJson, UpdateAtu} from "../_actions/atu.actions";
import {SignalItemModel} from "../../signals/_models/signals.model";
import {default as DEFAULT_GEO} from '../../../../assets/json/local/atu_regiony_geo.json';
import {default as DEFAULT_ATU} from '../../../../assets/json/ua_atu.json';
import {ClearAppAction} from "../../settings/_actions/settings.actions";


export const _AtuDefault: AtuModel = {
  items: [],
  geo: [],
  default_geo:[]
};

@State<AtuModel>({
  name: 'BH_APP_ATU',
  defaults: _AtuDefault,
})
@Injectable()
export class AtuState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private atuService: AtuService, private router: Router, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {

  }

  ngxsOnChanges(change: NgxsSimpleChange<AtuModel>): void {}

  ngxsAfterBootstrap(ctx: StateContext<AtuModel>) {
    ctx.dispatch(new LoadAtuFromJson());
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      default_geo: DEFAULT_GEO
    });
  }

  @Selector()
  static selectAtu(state: AtuModel) {
    return state.items;
  }
  @Selector()
  static selectAtuTree(state: AtuModel) {

    const itemMap: { [key: string]: AtuItemModel } = {};

    [...state.items].forEach(item => {
      itemMap[item.sid] = { ...item, children: [] };
    });

    const tree: AtuItemModel[] = [];

    [...state.items].forEach(item => {
      if (item.pid) {
        if (!itemMap[item.pid]) {
          return;
        }
        itemMap[item.pid].children!.push(itemMap[item.sid]);
      } else {
        tree.push(itemMap[item.sid]);
      }
    });
    return tree;
  }

  @Selector()
  static selectAtuGeo(state: AtuModel) {
    return state.geo;
  }

  @Selector()
  static selectDefaultGeo(state: AtuModel) {
    return state.default_geo;
  }

  @Action(ClearAppAction)
  public async clearAppAction(ctx: StateContext<AtuModel>) {
    ctx.setState(_AtuDefault);
  }

  @Action(UpdateAtu)
  updateAtu(ctx: StateContext<AtuModel>, UpdateAtu: { atu: AtuItemModel[]; }) {
    const state = ctx.getState();
    let _atu = UpdateAtu.atu;
    ctx.patchState({
      ...state,
      items: _atu
    });
  }


  @Action(LoadAtuFromJson)
  async loadAtuFromJson(ctx: StateContext<AtuModel>) {
    //const _atu = await this.atuService.getAtuFromJson().toPromise();
    const _atu = DEFAULT_ATU;
    ctx.dispatch(new UpdateAtu(_atu));
  }

  @Action(LoadAtuActualGeo)
  async loadAtuActualGeo(ctx: StateContext<AtuModel>, LoadAtuActualGeo: { signals: SignalItemModel[]; }) {
    const state = ctx.getState();
    const _signals_geo = await this.atuService.getActualGeo().toPromise();
    if(_signals_geo) {
      ctx.patchState({
        ...state,
        geo: this._combineArrays(this._convertToAtuGeoItemModels(state.items, _signals_geo), LoadAtuActualGeo.signals)
      });
    }


  }

  _convertToAtuGeoItemModels(regions, originalItems: any[]): AtuGeoItemModel[] {
    const findAllParents = (regionId: string): string => {
      const parents = [];
      let currentRegion = regions.find(region => region.sid === regionId);

      // Ensure the region exists before proceeding
      if (!currentRegion) return '';

      const selfSlug = currentRegion.slug; // Save the slug of the initial region for later use

      // Iterate through the parent regions until there's no parent or the root region is reached
      while (currentRegion && currentRegion.pid && currentRegion.sid !== '11000000000000000') {
        currentRegion = regions.find(region => region.sid === currentRegion.pid);
        if (currentRegion) {
          parents.push(currentRegion);
        }
      }

      // Reverse the parents array to start from the highest-level parent
      const reversedParents = parents.reverse();

      // Build the route URL using the slugs of parent regions in reversed order
      let routeUrl = '/region/' + reversedParents.map(parent => parent.slug).join('/') + '/' + selfSlug;

      return routeUrl.replace('ukraine/', '');
    };

    return originalItems.map(item => ({
      type: 'Feature',
      properties: {
        sid: item.sid,
        route: findAllParents(item.sid)
      },
      geometry: item.geometry,
    }));
  }

  _combineArrays(atuGeoItems: AtuGeoItemModel[], signalItems: SignalItemModel[]): AtuGeoItemModel[] {
    let combinedArray: AtuGeoItemModel[] = [];
    signalItems.forEach((signalItem) => {
      signalItem.signals.forEach((signal) => {
        const matchedAtuItem = atuGeoItems.find((atuItem) => atuItem.properties.sid === signalItem.sid);
        if (matchedAtuItem) {
          const newItem: AtuGeoItemModel = {
            ...matchedAtuItem,
            properties: {
              ...matchedAtuItem.properties,
              signalType: signal.type,
            }
          };
          combinedArray.push(newItem);
        }
      });
    });

    return combinedArray;
  }


}
