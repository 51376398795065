import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {UserModel} from "../_models/user.model";
import {ChangeLanguageAction, ChangeSoundAction, ClearAppAction} from "../../settings/_actions/settings.actions";
import {UserService} from "../_services/user.service";
import {initUser, updateUser} from "../_actions/user.actions";
import {TranslocoService} from "@ngneat/transloco";


export const _UserDefault: UserModel = {
  _id: null,
  email: null,
  first_name: "",
  last_name: "",
  display_name: "",
  avatar: "",
  role: "guest",
  friends: [],
  bin: null,
  settings: {
    sound: true,
    style: 'light',
    language: 'ua',
    atu: null
  },
  billing: {
    first_name: "",
    last_name: "",
    country: "",
    city: "",
    state: "",
    address: "",
    postcode: null,
    phone: null
  }
};

@State<UserModel>({
  name: 'BH_APP_USER',
  defaults: _UserDefault,
})
@Injectable()
export class UserState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private userService: UserService, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  ngxsOnChanges(change: NgxsSimpleChange<UserModel>): void {}

  ngxsAfterBootstrap(ctx: StateContext<UserModel>) {}



  @Selector()
  static selectSettings(state: UserModel) {
    return state.settings;
  }
  @Selector()
  static selectUser(state: UserModel) {
    return state;
  }


  @Action(ClearAppAction)
  public async clearAppAction(ctx: StateContext<UserModel>) {
    ctx.setState(_UserDefault);
  }

  @Action(ChangeSoundAction)
  public changeSoundAction(ctx: StateContext<UserModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        sound: !state.settings.sound
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      this.userService.updateSound(!state.settings.sound).subscribe();
    }
  }
  @Action(ChangeLanguageAction)
  public changeLanguageAction(ctx: StateContext<UserModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        language: payload.language,
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      this.userService.updateLanguage(payload.language).subscribe();
    }
  }


  @Action(initUser)
  public initUser(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    ctx.patchState({
      ..._state,
      first_name: _user.first_name,
      last_name: _user.last_name,
      display_name: _user.first_name + ' ' + _user.last_name,
      avatar: _user.avatar,
      email: _user.email,
      billing: {
        ..._state.billing,
        first_name: _user.first_name,
        last_name: _user.last_name,
      }
    });
  }
  @Action(updateUser)
  public updateUser(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    const _user_state = {..._userData.user};
    if(_user_state.password) {
      delete _user_state.password;
    }

    if(_state.settings.language != _user.settings.language) {
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
    }

    ctx.setState(_user_state);
    if (_state.role == 'user' || _state.role == 'premium'){
      let _update_user_fields = ['avatar', 'billing', 'display_name', 'email', 'first_name', 'last_name', 'password'];
      const updated_user_data = (_user, _update_user_fields) =>
        _update_user_fields.reduce((acc, key) => {
          if (_user.hasOwnProperty(key)) {
            acc[key] = _user[key];
          }
          return acc;
        }, {});
      const updatedUserData = updated_user_data(_user, _update_user_fields);
      this.userService.updateAccountData(updatedUserData).subscribe();
    }
  }

}
