import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngxs/store";
import {SignalsState} from "../_stores/signals/_state/signals.state";

@Injectable({
  providedIn: 'root',
})
export class MatchRegionChildGuard implements CanMatch {

  constructor(private store: Store) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.store.selectSnapshot(SignalsState.selectSignalsTypes).find((_type) => _type.slug == segments[segments.length - 1].path);
  }

}
