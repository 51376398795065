import {Inject, Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {DOCUMENT} from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class IframeMatchGuard implements CanMatch {

  constructor(@Inject(DOCUMENT) private document: Document) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    const isIframe = this.document.defaultView !== this.document.defaultView.top;
    return of(isIframe);
  }
}
