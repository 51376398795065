export const environment = {
  production: true,
  baseUrl: 'https://alarmmap.online',
  translocoUrl:'',
  api_endpoint: 'https://alarmmap.online',
  storeDevtools: false,
  hmr: false,
  apiUrl: '',
  default_language: 'ua',
  mapbox: {
    accessToken: 'sk.eyJ1IjoienB2cyIsImEiOiJjbDFia2VpMnQwOTQ5M2NsNTVwd3Vmbml5In0.mZ7BdDTWirIrMawGv4og6A',
  },
};

