export class ClearAppAction  {
  public static readonly type = '[System] ClearAppAction';
  constructor() {}
}
export class ChangeLanguageAction  {
  public static readonly type = '[System] ChangeLanguageAction';
  constructor(public language: string) {}
}
export class ChangeSoundAction  {
  public static readonly type = '[System] ChangeSoundAction';
  constructor() {}
}
export class ChangeKioskModeAction  {
  public static readonly type = '[System] ChangeKioskModeAction';
  constructor(public mode?: boolean) {}
}
export class ChangeStyleAction  {
  public static readonly type = '[System] ChangeStyleAction';
  constructor(public style: string) {}
}
export class ChangeMapStyleAction  {
  public static readonly type = '[System] ChangeMapStyleAction';
  constructor(public map_style: string) {}
}
export class SetSoundAction  {
  public static readonly type = '[System] SetSoundAction';
  constructor(public sound: boolean) {}
}
