import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AtuItemModel} from "../_models/atu.model";

@Injectable({
  providedIn: 'root',
})
export class AtuService {

  constructor(private http: HttpClient) {}

  public getAtuFromJson(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<AtuItemModel[]>(API.url('atu_ua'));
  }

  public get(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<AtuItemModel[]>(API.url('atu_ua'+randomHash));
  }

  public getActualGeo(){
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any[]>(API.url('geo/actual'+randomHash));
  }

}
